@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.exclusive {
  padding-top: 5%;
  padding-bottom: 5%;
  overflow: hidden;
}
.exclusive .inner {
  padding: 3%;
  border-radius: 22px;
  background: #EAF3FF;
}
.exclusive .inner div {
  width: 60%;
}
.exclusive .inner div h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4.5rem;
  color: #213654;
}
.exclusive .inner div p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  color: #374A65;
  margin-top: 2%;
}
.exclusive .inner div form {
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}
.exclusive .inner div form .email {
  width: 75%;
  padding: 2%;
  font-size: 1.3rem;
  border: 1px solid #7A8698;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  outline: none;
}
.exclusive .inner div form .email:focus {
  border: 1px solid #213654;
  color: #213654;
}
.exclusive .inner div form .submit {
  background: #213654;
  color: #fff;
  width: 20%;
  border: 1px solid #213654;
  border-radius: 10px;
  outline: none;
  font-size: 1.3rem;
}
.exclusive .inner div form .submit:hover {
  background: none;
  color: #213654;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 1024px) {
  .exclusive .inner div h1 {
    font-size: 1.75rem;
  }
  .exclusive .inner div p {
    font-size: 1rem;
  }
  .exclusive .inner div form .email {
    font-size: 0.75rem;
  }
  .exclusive .inner div form .submit {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 768px) {
  .exclusive .inner div {
    width: 100%;
  }
  .exclusive .inner div p {
    margin-top: 3%;
  }
  .exclusive .inner div form {
    margin-top: 5%;
  }
  .exclusive .inner div form .email {
    font-size: 1rem;
  }
  .exclusive .inner div form .submit {
    font-size: 1rem;
  }
}
@media screen and (max-width: 540px) {
  .exclusive .inner div {
    padding: 3%;
  }
  .exclusive .inner div h1, .exclusive .inner div p {
    text-align: left;
  }
  .exclusive .inner div p {
    margin-top: 5%;
  }
  .exclusive .inner div form {
    margin-top: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .exclusive .inner div form .email {
    font-size: 0.75rem;
    width: 100%;
    padding: 3%;
  }
  .exclusive .inner div form .submit {
    font-size: 0.75rem;
    width: 100%;
    padding: 0.8rem;
    margin-top: 5%;
  }
}/*# sourceMappingURL=styles.module.css.map */