@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.how {
    padding-top: 5%;
    padding-bottom: 5%;
    overflow: hidden;

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            width: 50%;

            h1 {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 4rem;
                line-height: 1.5;
                color: #213654;
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.5;
                margin-top: 2%;
                color: #374A65;
            }

            a {
                display: inline-block;
                color: #fff;
                background: #213654;
                border-radius: 10px;
                padding: 3%;
                text-decoration: none;
                font-size: 1.375rem;
                margin-top: 3%;

                &:hover{
                    background: none;
                    border:  1px solid #213654;
                    color: #213654;
                    transition: all .3s ease-in;
                }
            }
        }

        .vid {
            width: 50%;
            height: 400px;
            border-radius: 10px;

            video {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 10px;
            }
        }

        .hide {
            display: none;
        }
    }
}

@media screen and (max-width:1024px) {
    .how {
    
        .inner {
    
            .content {
    
                h1 {
                    font-size: 1.75rem;
                }
    
                p {
                    font-size: 1rem;
                }
    
                a {
                    font-size: 1rem; 
                }
            }
    
            .vid {
                height: 300px;
            }

        }
    } 
}

@media screen and (max-width:768px) {
    .how {
    
        .inner {
            flex-direction: column;
    
            .content {
                width: 100%;
                margin-top: 3%;
    
                h1 {
                    font-size: 1.75rem;
                    text-align: center;
                }
    
                p {
                    margin-top: 3%;
                    text-align: center;
                }
    
                a {
                    margin-top: 3%;
                    margin-left: 50%;
                    margin-right: 50%;
                    transform: translate(-50%) !important;
                    width: 50%;
                    text-align: center;
                }
            }
    
            .vid {
                margin-top: 3%;
                width: 100%;
                height: 300px;
            }

            .show {
                display: none;
            }

            .hide {
                display: block;
            }
        }
    } 
}

@media screen and (max-width:540px) {
    .how {
    
        .inner {
            flex-direction: column;
    
            .content {
                width: 100%;
                margin-top: 5%;
    
                h1 {
                    margin-top: 5%;
                    font-size: 1.75rem;
                    text-align: left;
                }
    
                p {
                    margin-top: 2%;
                    text-align: left;
                }
    
                a {
                    margin-top: 5%;
                    font-size: 0.75rem; 
                    margin-left: 0%;
                    margin-right: 0%;
                    transform: translate(0%)  !important;
                    width: auto;
                    text-align: left;
                    padding: 10px 20px;
                    border-radius: 4px;
                }
            }
    
            .vid {
                margin-top: 5%;
                height: 200px;
            }

        }
    } 
}