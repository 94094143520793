@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.download {
  padding-top: 3%;
  padding-bottom: 3%;
  background: #EAF3FF;
  overflow: hidden;
}
.download .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.download .inner .content {
  width: 60%;
  padding: 1;
}
.download .inner .content h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.5;
  color: #213654;
}
.download .inner .content .links__m {
  display: none;
}
.download .inner .imgCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.download .inner .imgCon .img_ {
  width: 65%;
}
.download .inner .imgCon .img_ img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.download .inner .imgCon .links__ {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.download .inner .imgCon .links__ a {
  margin-top: 5%;
  display: block;
  border-radius: 4px;
  width: 90%;
  height: 50px;
}
.download .inner .imgCon .links__ a img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.download .inner .imgCon .links__ a:hover {
  opacity: 0.8 !important;
  transform: all 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .download .inner .content h1 {
    font-size: 1.75rem;
  }
  .download .inner .imgCon .img_ {
    width: 60%;
  }
  .download .inner .imgCon .links__ {
    width: 40%;
  }
  .download .inner .imgCon .links__ a {
    margin-top: 5%;
    height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .download .inner .content {
    width: 70%;
  }
  .download .inner .content h1 {
    font-size: 1.75rem;
  }
  .download .inner .content .links__m {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
  }
  .download .inner .content .links__m a {
    width: 48%;
    border-radius: 4px;
    height: 50px;
  }
  .download .inner .content .links__m a img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .download .inner .content .links__m a:hover {
    opacity: 0.8;
    transform: all 0.3s ease;
  }
  .download .inner .imgCon {
    width: 30%;
  }
  .download .inner .imgCon .img_ {
    width: 100%;
  }
  .download .inner .imgCon .img_ img {
    -o-object-fit: contain;
       object-fit: contain;
  }
  .download .inner .imgCon .links__ {
    display: none;
    width: 40%;
  }
  .download .inner .imgCon .links__ a {
    margin-top: 5%;
    height: 50px;
  }
}/*# sourceMappingURL=styles.module.css.map */