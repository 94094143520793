@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
//navbar starts here
nav {
    background: #EAF3FF;
    //position: relative;
    z-index: 99;

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10vh;
        z-index: 2;
        padding-top: 2%;
        padding-bottom: 2%;
    
        .logo {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
    
            img {
                height: 48px;
                object-fit: cover;
            } 
        }
    
        .nav-links {
            display: flex;
            width: 45%; 
            justify-content: space-between;
            text-transform: capitalize;
        }
        
        .nav-links li {
            list-style: none;
            margin-left: 1rem;
            margin-top: -4.5px;
        }
        
        .nav-links a {
            color: #374A65;
            text-decoration: none;
            //letter-spacing: 3px;
            font-weight: 500;
            font-size: 1rem;
            position: relative;
            font-family: 'Poppins', sans-serif;
            
        }
    
        .nav-links a:hover {
            opacity: 0.9;
        }
        .nav-links a:focus {
            opacity: 0.7;
        }
        
        .nav-links a::after{
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            top: 25px;
            width: 0%;
            height: 2px;
            border-radius: 10px;
            margin: auto;
            transition: all .4s;
            opacity: 0;
            background-color: #374A65;
        }
    
        .nav-links a:hover::after{
            width: 100%;
            opacity: 1;
        }
    
        .activate {
            //color: #000 !important;
            font-weight: bold !important;
        }
    
        .burger {
            display: none;
            cursor: pointer;
            z-index: 99;
    
            .line0 {
                width: 30px;
                height: 2px;
                background: #374A65;
                margin: 5px;
                border-radius: 8px;
                transition: all 0.3s ease;
            }
            
            .line1 {
                width: 30px;
                height: 2px;
                background: #374A65;
                margin: 5px;
                border-radius: 8px;
                transition: all 0.3s ease;
            }
            
            .line2 {
                width: 30px;
                height: 2px;
                background: #374A65;
                margin: 5px;
                border-radius: 8px;
                transition: all 0.3s ease;
            }
        }

        .frame {
            display: none;
        }
        
    
        .bro {
            display: none;
            cursor: pointer;
            z-index: 99;
    
            .line0 {
                width: 30px;
                height: 2px;
                margin: 5px;
                border-radius: 8px;
                transform: rotate(-45deg) translate(-5px, 6px);
                background: #374A65;
                transition: all 0.3s ease;
            }
            
            .line1 {
                width: 30px;
                height: 2px;
                background: #374A65;
                margin: 5px;
                border-radius: 8px;
                transition: all 0.3s ease;
                opacity: 0;
            }
            
            .line2 {
                width: 30px;
                height: 2px;
                background: #374A65;
                margin: 5px;
                border-radius: 8px;
                transition: all 0.3s ease;
                transform: rotate(42deg) translate(-3.5px, -5.5px);
                margin-top: 5px;
            }
        }
        
        
        
    
    
    
        .request {
            a {
                border-radius: 4px;
                padding: 10px 20px !important;
                background: #374A65;
                color: #fff !important;
                font-size: 1rem;
                &:hover {
                    opacity: 0.8;
                    color: #374A65 !important;
                    background: #fff;
                    transition: all .3s ease-in;
                }
    
                &::after{
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    top: 25px;
                    width: 0%;
                    height: 0px;
                    border-radius: 10px;
                    margin: auto;
                    transition: all .4s;
                }
            }
        }
    }
}

@media screen and (max-width:1024px){
    nav {

        .inner {
            height: 8vh;
            .nav-links {
                width: 50%; 
                a {
                    font-size: 0.7rem;
                }
            }

            
        }
    }
   
}

@media screen and (max-width:768px) {

    nav {

        .inner {
            
            .nav-links {
                display: none;
            }
        
            .nav-links li {
                opacity: 0;
                margin-top: 0;
            }
        
            .burger, .bro {
                display: block;
            }
    
            .logo {
               z-index: 5;
                img {
                    height: 25px;
                    align-self: center;
                    justify-self: center;
                    margin-top: 5.5px;
                } 
               
            }
            .frame {
                display: flex;
                align-items: center;

                .pin {
                    border-radius: 4px;
                    padding: 7px 15px;
                    background: #374A65;
                    color: #fff;
                    text-decoration: none;
                    display: block;
                    margin-right: 1rem;
                    font-size: 0.75rem;

                    &:hover {
                        opacity: 0.8;
                        color: #374A65;
                        background: #fff;
                        transition: all .3s ease-in;
                        font-weight: bold;
                    }
            
                    &::after{
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        top: 25px;
                        width: 0%;
                        height: 0px;
                        border-radius: 10px;
                        margin: auto;
                        transition: all .4s;
                    }
                }
            }

           
        }
        
    }

    @keyframes navlinkFade {
        from {
            opacity: 0;
            transform: translateX(50px);
        }
    
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}

.nav-active {
    transform: translate(0%);
    position: absolute;
    right: 0;
    height: 100vh;
    top: 0;
    background: #EAF3FF;
    color: #374A65;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    transition: transform 0.5s ease-in;
    z-index: 4;

    li {
        list-style: none;
    }

    .request {
        display: none;
    }
    
    a {
        color: #374A65;
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 700;
        font-size: 0.8rem;
        position: relative;
        font-family: 'Poppins', sans-serif;
    }

    a:hover {
        opacity: 0.9;
    }
    a:focus {
        opacity: 0.7;
    }
    
    a::after{
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        top: 25px;
        width: 0%;
        height: 2px;
        border-radius: 10px;
        margin: auto;
        transition: all .4s;
        opacity: 0;
        background-color: #374A65;
    }

    a:hover::after{
        width: 100%;
        opacity: 1;
    }
}