@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.features {
  padding-top: 5%;
  padding-bottom: 5%;
  overflow: hidden;
}
.features .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.features .inner h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.5;
  color: #213654;
  text-align: center;
}
.features .inner .para {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #374A65;
  text-align: center;
}
.features .inner .cont {
  width: 100%;
  margin-top: 5%;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
}
.features .inner .cont .feature {
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  background: #F2FAFA;
  padding: 1%;
  border-radius: 8px 8px 0px 0px;
}
.features .inner .cont .feature h3 {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  color: #213654;
}
.features .inner .cont .feature1 {
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
.features .inner .cont .feature1 p {
  border-bottom: 1px solid #D4D4D4;
  padding: 5%;
  margin-bottom: 0;
  text-align: center;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #374A65;
  display: flex;
  justify-content: center;
  align-items: center;
}
.features .inner .cont .feature1 .green {
  color: #91D738;
}
.features .inner .cont .feature1 .red {
  color: #FF2A1F;
}

@media screen and (max-width: 1024px) {
  .features .inner h1 {
    font-size: 1.75rem;
  }
  .features .inner .para {
    font-size: 1rem;
  }
  .features .inner .cont .feature h3 {
    text-align: center;
    font-size: 1rem;
  }
  .features .inner .cont .feature1 p {
    border-bottom: 1px solid #D4D4D4;
    padding: 5%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1rem;
  }
}
@media screen and (max-width: 540px) {
  .features .inner h1 {
    font-size: 1.75rem;
  }
  .features .inner .para {
    font-size: 1rem;
    margin-top: 3%;
  }
  .features .inner .cont {
    margin-top: 5%;
  }
  .features .inner .cont .feature h3 {
    text-align: center;
    font-size: 0.8rem;
  }
  .features .inner .cont .feature1 p {
    border-bottom: 1px solid #D4D4D4;
    padding: 5%;
    margin-bottom: 0;
    text-align: center;
    font-size: 0.75rem;
  }
}/*# sourceMappingURL=styles.module.css.map */