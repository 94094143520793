@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
footer {
    background: #EAF3FF;
    padding-top: 5%;
    padding-bottom: 2%;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .foot_cont {
            width: 100%;
            display: flex;
            justify-content: space-between;
            //align-items: center;

            .cont {
                width: 30%;
                height: 100%;

                .img_con {
                    height: 48px;
                    width: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .icons_con {
                    margin-left: 5%;
                    margin-top: 1%;
                    width: 100%;
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    a {
                        color:  #263446;
                        text-decoration: none;
                        font-size: 1.5rem;
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }


            .links_main{
                width: 70%;
                display: grid;
                grid-template-columns: auto auto auto;

                div{

                    h1{
                        font-size: 1.875rem;
                        font-weight: 600;
                        color: #263446;
                        font-family: 'Poppins', sans-serif;
                    }

                    p {
                        margin-top: 3%;
                        font-size: 1rem;
                        font-weight: 500;
                        font-family: 'Poppins', sans-serif;
                        a {
                            text-decoration: none;
                            position: relative;
                            text-decoration: none;
                            color: #414D5E;
                            
                            &:hover {
                                opacity: 0.9;
                            }
                            &:focus {
                                opacity: 0.7;
                            }
                            
                            &::after{
                                position: absolute;
                                content: '';
                                bottom: 0;
                                left: 0;
                                top: 25px;
                                width: 0%;
                                height: 2px;
                                border-radius: 10px;
                                margin: auto;
                                transition: all .4s;
                                opacity: 0;
                                background-color: #263446;
                            }
                        
                            &:hover::after{
                                width: 100%;
                                opacity: 1;
                            }
                        }

                    }
                }
            }
        }

        .rights {
            margin-top: 2%;
            border-top: 1px solid #6E6E6E;
            width: 100%;
            padding: 2%;
            p {
                font-size: 1rem;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                color: #414D5E;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width:1024px){
    footer {
        .inner {
    
            .foot_cont {

                .cont {
    
                    .icons_con {
    
                        a {
                            text-decoration: none;
                            font-size: 0.8rem;
                        }
                    }
                }
    
    
                .links_main{
    
                    div{
    
                        h1{
                            font-size: 1rem;
                        }
    
                        p {
                            font-size: 0.8rem;
    
                        }
                    }
                }
            }
    
            .rights {
                p {
                    font-size: 0.8rem;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    footer {
    
        .inner {
    
            .foot_cont {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .cont {
                    width: 100%;
                    height: 100%;
    
                    .img_con {
                        height: 58px;
                        width: 50%;
                        img {
                            width: 100px;
                            height: 100%;
                            object-fit: none;
                        }
                    }
    
                    .icons_con {
                        margin-top: 1%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-left: 0 !important;
                        a {
                            margin: 0;
                            font-size: 1rem;
                        }
                    }
                }
    
                .links_main{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 50% 50%;
                    padding: 0;
    
                    div{
                        margin-top: 5%;
                        padding: 0%;
                    }
                }
            }
    
            .rights {
                margin-top: 2%;
                border-top: 1px solid #6E6E6E;
                width: 100%;
                
                p {
                    margin-top: 2%;
                }
            }
        }
    }
}