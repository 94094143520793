@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.testimonies_ {
  padding-top: 10%;
  padding-bottom: 5%;
}
.testimonies_ .inner_ {
  background: #EAF3FF;
  border-radius: 16px;
  padding: 3%;
}
.testimonies_ .inner_ h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.5;
  color: #213654;
  text-align: center;
}
.testimonies_ .inner_ p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #374A65;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .testimonies_ .inner_ h1 {
    font-size: 1.75rem;
  }
  .testimonies_ .inner_ p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .testimonies_ .inner_ {
    background: #fff;
  }
}/*# sourceMappingURL=styles.module.css.map */