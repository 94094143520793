@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.download {
    padding-top: 3%;
    padding-bottom: 3%;
    background: #EAF3FF;
    overflow: hidden;

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            width: 60%;
            padding: 1;
            h1 {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 4rem;
                line-height: 1.5;
                color: #213654;
            }

            .links__m {
                display: none;
            }
        }

        .imgCon {
            display: flex;
            justify-content: space-between;
            align-items: center; 
            width: 40%;

            .img_ {
                width: 65%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .links__ {
                width: 45%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                a {
                    margin-top: 5%;
                    display: block;
                    border-radius: 4px;
                    width: 90%;
                    height: 50px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    &:hover {
                        opacity: 0.8 !important;
                        transform:  all .3s ease;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .download {
        .inner {
    
            .content {
                h1 {
                    font-size: 1.75rem;
                }
            }
    
            .imgCon {
    
                .img_ {
                    width: 60%;
                }
    
                .links__ {
                    width: 40%;
    
                    a {
                        margin-top: 5%;
                        height: 50px;
                    }
                }
            }
        }
    } 
}

@media screen and (max-width:768px) {
    .download {
        .inner {
    
            .content {
                width: 70%;
                h1 {
                    font-size: 1.75rem;
                }

                .links__m {
                    width: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 2%;
                    a {
                        width: 48%;
                        border-radius: 4px;
                        height: 50px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
    
                        &:hover {
                            opacity: 0.8;
                            transform:  all .3s ease;
                        }
                    }
                }
            }
    
            .imgCon {
                width: 30%;
    
                .img_ {
                    width: 100%;

                    img {
                        object-fit: contain;
                    }
                    
                }
    
                .links__ {
                    display: none;
                    width: 40%;
    
                    a {
                        margin-top: 5%;
                        height: 50px;
                    }
                }
            }
        }
    } 
}