* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.privacy-box a {
  color: cornflowerblue;
  text-decoration: underline;
}

body::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

/* Track */
body::-webkit-scrollbar-track {
  cursor: pointer;
  margin-right: 4px;
  background-color: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #bbb;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #21365477;
  opacity: 0.5;
  margin-right: 4px;
  height: 30px !important;
  max-height: 30px !important;
  border-radius: 3px;
  margin: 4px;
}

.my-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .my-container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .my-container {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .my-container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .my-container {
    width: 1140px;
  }
}
