@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.swiper {
  background: #fff;
  border-radius: 6px;
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin-left: 50%;
  margin-right: 50%;
  transform: translate(-50%);
  padding: 5%;
  margin-top: 3%;
}
.swiper .rate_ {
  display: flex;
}
.swiper .rate_ .con {
  color: #F4BC4D;
  margin: 0.5rem;
}
.swiper .rate_ .con:hover {
  opacity: 0.8;
  transition: all 0.3s ease-in;
}
.swiper .comment_ {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #77828F;
  margin-top: 3%;
}
.swiper .individual_ {
  margin-top: 3%;
  display: flex;
  align-items: center;
}
.swiper .individual_ .imgCon_ {
  width: 70px;
  height: 70px;
}
.swiper .individual_ .imgCon_ img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
.swiper .individual_ .name_loc {
  margin-left: 1rem;
}
.swiper .individual_ .name_loc h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #263446;
}
.swiper .individual_ .name_loc span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #77828F;
  display: block;
  margin-top: 5%;
}

@media screen and (max-width: 1024px) {
  .swiper .rate_ .con {
    font-size: 0.8rem;
    margin: 0.2rem;
  }
  .swiper .comment_ {
    font-size: 0.8rem;
  }
  .swiper .individual_ .imgCon_ {
    width: 50px;
    height: 50px;
  }
  .swiper .individual_ .name_loc h5 {
    font-size: 0.8rem;
  }
  .swiper .individual_ .name_loc span {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  .swiper {
    width: 100%;
    background: #EAF3FF;
    box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.03);
  }
}
@media screen and (max-width: 540px) {
  .swiper {
    margin-top: 5%;
  }
}/*# sourceMappingURL=styles.module.css.map */