.load {
  position: fixed;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000000000000100;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=styles.module.css.map */