@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.exclusive {
    padding-top: 5%;
    padding-bottom: 5%;
    overflow: hidden;

    .inner {
        padding: 3%;
        border-radius: 22px;
        background: #EAF3FF;
        div {
            width: 60%;

            h1 {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 4.5rem;
                color: #213654;
            }
    
            p {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                color: #374A65;
                margin-top: 2%;
            }
    
            form {
                margin-top: 3%;
                display: flex;
                justify-content: space-between;
    
                .email {
                    width: 75%;
                    padding: 2%;
                    font-size: 1.3rem;
                    border: 1px solid #7A8698;
                    border-radius: 10px;
                    font-family: 'Poppins', sans-serif;
                    outline: none;
    
                    &:focus {
                        border: 1px solid #213654;
                        color: #213654;
                    }
    
                }
    
                .submit {
                    background: #213654;
                    color: #fff;
                    width: 20%;
                    border: 1px solid #213654;
                    border-radius: 10px;
                    outline: none;
                    font-size: 1.3rem;
    
                    &:hover {
                        background: none;
                        color: #213654;
                        transition: all .3s ease-in;
                    }
                }
            }
        }

        
    }
}

@media screen and (max-width:1024px) {
    .exclusive {
    
        .inner {
            div {
                h1 {
                    font-size: 1.75rem;
                }
        
                p {
                    font-size: 1rem;
                }
        
                form {
        
                    .email {
                        font-size: 0.75rem;
        
                    }
        
                    .submit {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .exclusive {
        .inner {
            div {
                width: 100%;
                p {
                    margin-top: 3%;
                }
        
                form {
                    margin-top: 5%;
        
                    .email {
                        font-size: 1rem;
        
                    }
        
                    .submit {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:540px) {
    .exclusive {
        .inner {
            div {
                padding: 3%;

                h1,p {
                    text-align: left;
                }
        
                p {
                    margin-top: 5%;
                }
        
                form {
                    margin-top: 5%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
        
                    .email {
                        font-size: 0.75rem;
                        width: 100%;
                        padding: 3%;
        
                    }
        
                    .submit {
                        font-size: 0.75rem;
                        width: 100%;
                        padding: 0.8rem;
                        margin-top: 5%;
                    }
                }
            }
        }
    }
}