@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.how {
  padding-top: 5%;
  padding-bottom: 5%;
  overflow: hidden;
}
.how .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.how .inner .content {
  width: 50%;
}
.how .inner .content h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.5;
  color: #213654;
}
.how .inner .content p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 2%;
  color: #374A65;
}
.how .inner .content a {
  display: inline-block;
  color: #fff;
  background: #213654;
  border-radius: 10px;
  padding: 3%;
  text-decoration: none;
  font-size: 1.375rem;
  margin-top: 3%;
}
.how .inner .content a:hover {
  background: none;
  border: 1px solid #213654;
  color: #213654;
  transition: all 0.3s ease-in;
}
.how .inner .vid {
  width: 50%;
  height: 400px;
  border-radius: 10px;
}
.how .inner .vid video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
}
.how .inner .hide {
  display: none;
}

@media screen and (max-width: 1024px) {
  .how .inner .content h1 {
    font-size: 1.75rem;
  }
  .how .inner .content p {
    font-size: 1rem;
  }
  .how .inner .content a {
    font-size: 1rem;
  }
  .how .inner .vid {
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  .how .inner {
    flex-direction: column;
  }
  .how .inner .content {
    width: 100%;
    margin-top: 3%;
  }
  .how .inner .content h1 {
    font-size: 1.75rem;
    text-align: center;
  }
  .how .inner .content p {
    margin-top: 3%;
    text-align: center;
  }
  .how .inner .content a {
    margin-top: 3%;
    margin-left: 50%;
    margin-right: 50%;
    transform: translate(-50%) !important;
    width: 50%;
    text-align: center;
  }
  .how .inner .vid {
    margin-top: 3%;
    width: 100%;
    height: 300px;
  }
  .how .inner .show {
    display: none;
  }
  .how .inner .hide {
    display: block;
  }
}
@media screen and (max-width: 540px) {
  .how .inner {
    flex-direction: column;
  }
  .how .inner .content {
    width: 100%;
    margin-top: 5%;
  }
  .how .inner .content h1 {
    margin-top: 5%;
    font-size: 1.75rem;
    text-align: left;
  }
  .how .inner .content p {
    margin-top: 2%;
    text-align: left;
  }
  .how .inner .content a {
    margin-top: 5%;
    font-size: 0.75rem;
    margin-left: 0%;
    margin-right: 0%;
    transform: translate(0%) !important;
    width: auto;
    text-align: left;
    padding: 10px 20px;
    border-radius: 4px;
  }
  .how .inner .vid {
    margin-top: 5%;
    height: 200px;
  }
}/*# sourceMappingURL=styles.module.css.map */