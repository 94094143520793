.dongle {
  padding-top: 5%;
  padding-bottom: 5%;
  overflow: hidden;
}
.dongle .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dongle .inner .content {
  width: 50%;
}
.dongle .inner .content h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.5;
  color: #213654;
}
.dongle .inner .content p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 2%;
  color: #374A65;
}
.dongle .inner .content a {
  display: inline-block;
  color: #fff;
  background: #213654;
  border-radius: 10px;
  padding: 3%;
  text-decoration: none;
  font-size: 1.375rem;
  margin-top: 4%;
}
.dongle .inner .content a:hover {
  background: none;
  border: 1px solid #213654;
  color: #213654;
  transition: all 0.3s ease-in;
}
.dongle .inner .imgCon {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.dongle .inner .imgCon span {
  width: 80%;
  height: 80%;
  background: #EAF3FF;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
}
.dongle .inner .imgCon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  z-index: 3;
}
.dongle .inner .hide {
  display: none;
}

@media screen and (max-width: 1024px) {
  .dongle .inner .content h1 {
    font-size: 1.75rem;
  }
  .dongle .inner .content p {
    font-size: 1rem;
  }
  .dongle .inner .content a {
    margin-top: 2%;
    font-size: 0.75rem;
  }
  .dongle .inner .imgCon {
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .dongle .inner {
    flex-direction: column;
  }
  .dongle .inner .content {
    width: 100%;
    margin-top: 3%;
  }
  .dongle .inner .content h1 {
    text-align: center;
  }
  .dongle .inner .content p {
    line-height: 2;
    text-align: center;
  }
  .dongle .inner .content a {
    margin-top: 2%;
    margin-left: 50%;
    margin-right: 50%;
    transform: translate(-50%);
    width: 50%;
    text-align: center;
  }
  .dongle .inner .imgCon {
    width: 100%;
    height: 400px;
  }
  .dongle .inner .hide {
    display: block;
  }
  .dongle .inner .show {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .dongle .inner .content {
    width: 100%;
    margin-top: 5%;
  }
  .dongle .inner .content h1, .dongle .inner .content p {
    text-align: left;
  }
  .dongle .inner .content p {
    margin-top: 1%;
    line-height: 2;
  }
  .dongle .inner .content a {
    margin-top: 3%;
    font-size: 0.75rem;
    margin-left: 0%;
    margin-right: 0%;
    transform: translate(0%);
    width: auto;
    text-align: left;
    padding: 10px 20px;
    border-radius: 4px;
  }
  .dongle .inner .imgCon {
    width: 100%;
    height: 200px;
    margin-top: 3%;
  }
  .dongle .inner .imgCon span {
    display: none;
    width: 60%;
    height: 50%;
  }
}/*# sourceMappingURL=styles.module.css.map */