@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
footer {
  background: #EAF3FF;
  padding-top: 5%;
  padding-bottom: 2%;
}
footer .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
footer .inner .foot_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
footer .inner .foot_cont .cont {
  width: 30%;
  height: 100%;
}
footer .inner .foot_cont .cont .img_con {
  height: 48px;
  width: 50%;
}
footer .inner .foot_cont .cont .img_con img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
footer .inner .foot_cont .cont .icons_con {
  margin-left: 5%;
  margin-top: 1%;
  width: 100%;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .inner .foot_cont .cont .icons_con a {
  color: #263446;
  text-decoration: none;
  font-size: 1.5rem;
}
footer .inner .foot_cont .cont .icons_con a:hover {
  opacity: 0.8;
}
footer .inner .foot_cont .links_main {
  width: 70%;
  display: grid;
  grid-template-columns: auto auto auto;
}
footer .inner .foot_cont .links_main div h1 {
  font-size: 1.875rem;
  font-weight: 600;
  color: #263446;
  font-family: "Poppins", sans-serif;
}
footer .inner .foot_cont .links_main div p {
  margin-top: 3%;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
footer .inner .foot_cont .links_main div p a {
  text-decoration: none;
  position: relative;
  text-decoration: none;
  color: #414D5E;
}
footer .inner .foot_cont .links_main div p a:hover {
  opacity: 0.9;
}
footer .inner .foot_cont .links_main div p a:focus {
  opacity: 0.7;
}
footer .inner .foot_cont .links_main div p a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 2px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
  opacity: 0;
  background-color: #263446;
}
footer .inner .foot_cont .links_main div p a:hover::after {
  width: 100%;
  opacity: 1;
}
footer .inner .rights {
  margin-top: 2%;
  border-top: 1px solid #6E6E6E;
  width: 100%;
  padding: 2%;
}
footer .inner .rights p {
  font-size: 1rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #414D5E;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  footer .inner .foot_cont .cont .icons_con a {
    text-decoration: none;
    font-size: 0.8rem;
  }
  footer .inner .foot_cont .links_main div h1 {
    font-size: 1rem;
  }
  footer .inner .foot_cont .links_main div p {
    font-size: 0.8rem;
  }
  footer .inner .rights p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  footer .inner .foot_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  footer .inner .foot_cont .cont {
    width: 100%;
    height: 100%;
  }
  footer .inner .foot_cont .cont .img_con {
    height: 58px;
    width: 50%;
  }
  footer .inner .foot_cont .cont .img_con img {
    width: 100px;
    height: 100%;
    -o-object-fit: none;
       object-fit: none;
  }
  footer .inner .foot_cont .cont .icons_con {
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0 !important;
  }
  footer .inner .foot_cont .cont .icons_con a {
    margin: 0;
    font-size: 1rem;
  }
  footer .inner .foot_cont .links_main {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0;
  }
  footer .inner .foot_cont .links_main div {
    margin-top: 5%;
    padding: 0%;
  }
  footer .inner .rights {
    margin-top: 2%;
    border-top: 1px solid #6E6E6E;
    width: 100%;
  }
  footer .inner .rights p {
    margin-top: 2%;
  }
}/*# sourceMappingURL=styles.module.css.map */