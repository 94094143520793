.load {
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}