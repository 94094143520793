@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.banner{
    overflow: hidden;
    padding-top: 5%;
    padding-bottom: 5%;
    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            width: 45%;
            h1 {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 4rem;
                line-height: 1.5;
                color: #213654;
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.5;
                margin-top: 5%;
                color: #374A65;
            }
        }

        .imgCon {
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 500px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: relative;
                z-index: 3;
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .banner{
        .inner {
    
            .content {
                h1 {
                    font-size: 3rem;
                }
    
                p {
                    font-size: 1.5rem;
                }
            }
    
            .imgCon {
                height: 500px;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .banner{
        padding: 3%;
        margin-top: 8vh;
        .inner {
            flex-direction: column;
            .content {
                width: 100%;
                margin-top: 3%;
                h1 {
                    font-size: 1.75rem;
                }
    
                p {
                    margin-top: 3%;
                    line-height: 2;
                    font-size: 1rem;
                }
            }
    
            .imgCon {
                width: 100%;
                height: 300px;
            }
        }
    }
}

@media screen and (max-width:540px) {
    .banner{
        .inner {
            
    
            .content {
                width: 100%;
                margin-top: 5%;
                h1 {
                    font-size: 1.75rem;
                }
    
                p {
                    margin-top: 5%;
                    line-height: 2;
                }
            }
    
            .imgCon {
                width: 100%;
                height: 300px;
                margin-top: 5%;
            }
        }
    }
}